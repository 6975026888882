import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { remove } from 'lodash';
import { Observable, filter, map, of, take } from 'rxjs';

import { DefaultModalConfigConstant } from '@app/modules/ui-components/modals/config/default-modal-config.constant';
import { PropertyUnitSelectorModalComponent } from '@main-application/service/components/property-unit-selector-modal/property-unit-selector-modal.component';
import { selectTenantData } from '@main-application/store/selectors/user.selectors';
import { UnitAttachmentType } from '@shared/enums/attachment-type';
import { TicketType } from '@shared/enums/ticket-type';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { GalleryItem } from '@shared/interfaces/gallery-item';
import { ListUpdateAction } from '@shared/interfaces/list-update-action.interface';
import { ConfirmationModalData, ModalData } from '@shared/interfaces/modal-data';
import { RestTicketModel, RestTurnoverDataModel } from '@shared/interfaces/turnover.interface';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import { WorkflowAction } from '@shared/interfaces/workflow-action.interface';
import { AddBidModalComponent, AddBidModalData } from '@ui-components/modals/add-bid-modal/add-bid-modal.component';
import { AddTicketModalComponent, TicketData } from '@ui-components/modals/add-ticket-modal/add-ticket-modal.component';
import {
  AddVendorUserModalComponent,
  AddVendorUserModalData,
  AddVendorUserModalResult,
} from '@ui-components/modals/add-vendor-user-modal/add-vendor-user-modal.component';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { GalleryModalComponent } from '@ui-components/modals/gallery-modal/gallery-modal.component';
import { InfoModalComponent } from '@ui-components/modals/info-modal/info-modal.component';
import {
  NewTurnoverModalComponent,
  NewTurnoverModalData,
} from '@ui-components/modals/new-turnover-modal/new-turnover-modal.component';
import {
  NextStepAssigneeModalComponent,
  NextStepAssigneeModalData,
} from '@ui-components/modals/next-step-assignee-modal/next-step-assignee-modal.component';
import {
  PromptModalComponent,
  PromptModalData,
  PromptModalResult,
} from '@ui-components/modals/prompt-modal/prompt-modal.component';
import {
  UserPreviewModalComponent,
  UserPreviewModalData,
  UserPreviewModalResult,
} from '@ui-components/modals/user-preview-modal/user-preview-modal.component';

import { AddVendorModalComponent, AddVendorModalData } from './add-vendor-modal/add-vendor-modal.component';
import { CommentTooLongModalComponent } from './comment-too-long-modal/comment-too-long-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ResidentInspectDetailsComponent } from './resident-inspect-details/resident-inspect-details.component';
import { TurnoverPunchModalComponent } from './turnover-punch-modal/turnover-punch-modal.component';
import { environment } from '../../../../environments/environment';

const ModalDialogSizes = {
  xxs: '280px',
  xs: '350px',
  sm: '472px',
  md: '572px',
  lg: '650px',
};

export type ModalDialogSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | number;

type TicketModalData = ListUpdateAction | TicketData;

/** Remove specific logic from base modal service. Then merge it with ModalService */
@Injectable({ providedIn: 'root' })
export class ModalsService {
  nonPmsUnitCost$ = this.store.select(selectTenantData).pipe(
    filter(tenantData => !!tenantData),
    take(1),
    map(tenantData => tenantData.nonPmsUnitCost)
  );

  constructor(protected dialog: MatDialog, protected bottomSheet: MatBottomSheet, protected store: Store<{}>) {}

  updateDialogHiddenState(hidden: boolean, data: any) {
    const state = localStorage.getItem('dialogsHiddenByUser');
    try {
      const parsed: any[] = state ? JSON.parse(state) : [];
      if (hidden) {
        parsed.push({
          data,
        });
      } else {
        remove(parsed, d => JSON.stringify(d.data) === JSON.stringify(data));
      }
      localStorage.setItem('dialogsHiddenByUser', JSON.stringify(parsed));
    } catch (e) {}
  }

  isDialogHiddenByUser(data: any) {
    const state = localStorage.getItem('dialogsHiddenByUser');
    try {
      const parsed: any[] = JSON.parse(state);
      const dialog = parsed.find(d => JSON.stringify(d.data) === JSON.stringify(data));
      return dialog !== undefined;
    } catch (e) {}
    return false;
  }

  openInfoModal(data: ModalData) {
    return this.dialog.open(InfoModalComponent, {
      autoFocus: false,
      panelClass: 'info-modal-container',
      data,
    });
  }

  openCommentTooLongModal(maxCounter: number) {
    this.dialog.open(CommentTooLongModalComponent, {
      panelClass: 'comment-too-long-modal-container',
      data: maxCounter,
    });
  }

  openGalleryModal(data: GalleryItem<UnitAttachmentType, RestGenericTypedAttachment>) {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: '1170px',
      maxHeight: '100vh',
      panelClass: 'gallery-modal-container',
      data,
    };

    this.dialog.open(GalleryModalComponent, config);
  }

  openNewTurnoverModal(activeProperty: number[], isNonPms: boolean) {
    this.dialog.open<NewTurnoverModalComponent, NewTurnoverModalData>(NewTurnoverModalComponent, {
      autoFocus: false,
      width: '454px',
      minHeight: '368px',
      panelClass: 'new-turnover-modal-container',
      data: { activePropertyIds: activeProperty, isNonPms: isNonPms },
    });
  }

  openNextStepAssigneeModal(
    turnoverData: RestTurnoverDataModel,
    workflowStep: WorkflowStepEnumType,
    propertyId: number,
    propertyName: string
  ) {
    return this.dialog.open<NextStepAssigneeModalComponent, NextStepAssigneeModalData, WorkflowAction>(
      NextStepAssigneeModalComponent,
      {
        autoFocus: false,
        width: '412px',
        panelClass: 'next-step-assignee-modal-container',
        disableClose: true,
        data: {
          nextWorkflowStep: workflowStep,
          turnoverData,
          propertyId,
          propertyName,
        },
      }
    );
  }

  addTicketModal(data: TicketModalData, ticketType = TicketType.Other, inspectionPmsType = 0, fromServicePage = false) {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: '683px',
      maxHeight: '100vh',
      panelClass: 'ticket-preview-modal-container',
      data: { ...data, ticketType, inspectionPmsType, fromServicePage },
    };

    return this.dialog.open(AddTicketModalComponent, config);
  }

  openPunchModal(data: RestTicketModel) {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: '1171px',
      maxHeight: '100vh',
      panelClass: 'punch-modal-container',
      disableClose: true,
      data,
    };

    return this.dialog.open(TurnoverPunchModalComponent, config);
  }

  openPrompt(data: PromptModalData, size: ModalDialogSize = 'sm') {
    return this.dialog.open<PromptModalComponent, PromptModalData, PromptModalResult>(PromptModalComponent, {
      ...DefaultModalConfigConstant,
      width: typeof size === 'number' ? `${size}px` : ModalDialogSizes[size],
      data,
    });
  }

  openNewVendorModal(data: AddVendorModalData) {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: '572px',
      maxHeight: '100vh',
      panelClass: 'add-vendor-modal-container',
      disableClose: true,
      data,
    };

    return this.dialog.open<AddVendorModalComponent, AddVendorModalData, RestCompanyModel>(
      AddVendorModalComponent,
      config
    );
  }

  getAcceptChargesText() {
    return this.nonPmsUnitCost$.pipe(
      map(cost => {
        const version = environment.ver.split('.').map(e => +e);
        const isBeta = environment.apiUrl.includes('beta');
        if (isBeta && (version[0] < 5 || (version[0] == 5 && version[1] < 6))) {
          return `There will be a monthly fee of $${cost} (waived until 01/01/24) for this unit for each month it is active.`;
        }
        return `There will be a monthly fee of $${cost} for this unit for each month it is active.`;
      })
    );
  }

  openConfirmationModal(
    data: ConfirmationModalData,
    size: ModalDialogSize = 'sm',
    matConfig?: MatDialogConfig
  ): MatDialogRef<ConfirmationModalComponent, DialogResult> {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: typeof size === 'number' ? `${size}px` : ModalDialogSizes[size],
      maxHeight: '80vh',
      panelClass: 'confirmation-modal-container',
      disableClose: matConfig?.disableClose ?? true,
      data,
    };

    if (data.doNotShowAgain && this.isDialogHiddenByUser(data)) {
      return {
        afterClosed(): Observable<any> {
          return of(DialogResult.Success);
        },
      } as MatDialogRef<ConfirmationModalComponent, DialogResult>;
    } else {
      return this.dialog.open(ConfirmationModalComponent, config);
    }
  }

  openUserPreviewModal(user?: UserWithGeneralRole, preselect = false, vendorId?: number, header?: string) {
    const config: MatDialogConfig<UserPreviewModalData> = {
      ...DefaultModalConfigConstant,
      width: '572px',
      panelClass: 'user-preview-modal-container',
      data: { user, preselect, vendorId, header },
    };

    return this.dialog.open<UserPreviewModalComponent, UserPreviewModalData, UserPreviewModalResult>(
      UserPreviewModalComponent,
      config
    );
  }

  openVendorUserModal(vendorId?: number, header?: string) {
    const config: MatDialogConfig<AddVendorUserModalData> = {
      ...DefaultModalConfigConstant,
      width: '572px',
      panelClass: 'add-vendor-user-modal-container',
      data: { vendorId, header },
    };

    return this.dialog.open<AddVendorUserModalComponent, AddVendorUserModalData, AddVendorUserModalResult>(
      AddVendorUserModalComponent,
      config
    );
  }

  addBid(turnoverId: number) {
    const config: MatDialogConfig<AddBidModalData> = {
      ...DefaultModalConfigConstant,
      width: '450px',
      panelClass: 'add-bid-modal-container',
      data: {
        turnoverId,
      },
    };

    return this.dialog.open(AddBidModalComponent, config);
  }

  openPropertyUnitSelectorModal() {
    return this.dialog.open<PropertyUnitSelectorModalComponent>(PropertyUnitSelectorModalComponent, {
      autoFocus: false,
      width: '454px',
      panelClass: 'property-unit-selector-modal-container',
    });
  }

  openResidentInspectDetails(item: any) {
    const bottomSheetRef = this.bottomSheet.open(ResidentInspectDetailsComponent, {
      data: item,
      panelClass: 'resident-inspect-details-modal',
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
    });

    return bottomSheetRef.afterDismissed();
  }
}
